<template>
  <div class="hello" ref="chartdiv"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

am4core.useTheme(am4themes_kelly);
export default {

  name: "HelloWorld",
  props:{
    id_evento:Number
  },
   mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

    chart.paddingRight = 20;

    // let data = [];

    chart.data = this.lista_pdv_ingressos_grafico[0]

  let tipos =  this.lista_pdv_ingressos_grafico[1]

           
   console.log(tipos)

    //console.log('chart', chart);

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "tipo";
    categoryAxis.title.text = "EVENTO";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Expenditure (M)";

    // Create series
    var series =[]
    let i =0;
    tipos.forEach(element => {
       series[i] = chart.series.push(new am4charts.ColumnSeries());
     series[i].dataFields.valueY = element.valueY;
     series[i].dataFields.categoryX = "tipo";
     series[i].name = element.name;
     series[i].tooltipText = "{name}: [bold]{valueY}[/]";
     series[i].stacked = true;
     i++
    });


    // series[] = chart.series.push(new am4charts.ColumnSeries());
    //  series[1].dataFields.valueY = "lmLoteria";
    //  series[1].dataFields.categoryX = "country";
    //  series[1].name = "LM SORVETERIA";
    //  series[1].tooltipText = "{name}: [bold]{valueY}[/]";
    //  series[1].stacked = true;


    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  created(){
   
  },
  computed: {
    lista_pdv_ingressos_grafico() {
      return this.$store.state.pdvsEventoIngresso.lista_pdvs_evento_ingressos_graficos;
    }
  },
  methods: {
    async listar_pdv_ingressos_grafico() {
      await this.$store.dispatch(
        "pdvsEventoIngresso/listar_pdv_ingressos_grafico",this.id_evento
      );

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 600px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 3pt;
}
</style>