<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-4 mt-5">
      <a
        @click="trocar_pagina('gerenFuncionarios')"
        class="card card-custom wave wave-animate-fast wave-success"
      >
        <div class="card-body table-responsive">
          <div class="d-flex align-items-cente">
            <div class="mr-6">
              <span class="svg-icon svg-icon-success svg-icon-4x">
                <svg>...</svg>
              </span>
            </div>
            <div class="d-flex flex-column">
              <a
                @click="trocar_pagina('gerenFuncionarios')"
                class="
                  text-dark text-hover-danger
                  font-weight-bold font-size-h4
                  mb-3
                "
              >
                Usuarios {{ count_cliente.usucount }}
              </a>
              <div class="text-dark-75">Aqui pode visualizar Funcionarios</div>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div v-show="lista_permissoes_filial.ra_Evento" class="col-xxl-4 mt-5">
      <a
        @click="trocar_pagina('evento')"
        class="card card-custom wave wave-animate-fast wave-primary"
      >
        <div class="card-body table-responsive">
          <div class="d-flex align-items-cente">
            <div class="mr-6">
              <span class="svg-icon svg-icon-success svg-icon-4x">
                <svg>...</svg>
              </span>
            </div>
            <div class="d-flex flex-column">
              <a
                @click="trocar_pagina('evento')"
                class="
                  text-dark text-hover-danger
                  font-weight-bold font-size-h4
                  mb-3
                "
              >
              Eventos {{ count_cliente.eventos_ativos }}
              </a>
              <div class="text-dark-75">
                Acessar Eventos 
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="row justify-content-center align-items-md-center pt-5">
      <div class="row col-md-10">
        <v-card elevation="15" class="p-3">
          <div class="row">
            <div class="col-md-9 mb-3">
              <!-- <label class="col-md-12">Eventos:*</label> -->
              <treeselect
                :multiple="false"
                :options="lista_eventos"
                placeholder="Selecione o evento..."
                v-model="eventos_id"
                @input="listar_pdv_ingressos_grafico"
              />
            </div>
            <div class="col-md-3 text-end">
              <v-btn
                color="primary"
                depressed
                elevation="2"
                outlined
                @click="rel()"
                >Relatorio</v-btn
              >
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <graficos
      v-if="sh"
      :id_evento="eventos_id"
    ></graficos>
  </div>

  <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import graficos from "@/view/components/dashbords/grafico.vue";
export default {
  name: "dashboard",
  components: { graficos },
  data() {
    return {
      eventos_id: null,
      sh:false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.$store.dispatch(
        "configUsuarios/count",
      
      );
  },
 
  computed: {
    count_cliente(){
      return this.$store.state.configUsuarios.count_cliente
    },
    lista_pdv_ingressos_grafico() {
      return this.$store.state.pdvsEventoIngresso
        .lista_pdvs_evento_ingressos_graficos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_eventos() {
      return this.$store.state.evento.lista_eventos.map((cat) => ({
        id: cat.id,
        label: cat.nome_evento + " - " + cat.id,
      }));
    },
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    trocar_pagina(rota) {
      this.$router.push({ name: rota });
    },
    async listar_pdv_ingressos_grafico() {
      this.sh = false
      await this.$store.dispatch(
        "pdvsEventoIngresso/listar_pdv_ingressos_grafico",
        this.eventos_id
      );
      this.sh = true

    },
    rel() {
      if (!this.eventos_id) {
        this.alertMessage("warning", "Seleciona um Evento");
      } else {
        window.open(
          process.env.VUE_APP_API_URL +
            "pdvs_ingresso/relatorio/" +
            this.eventos_id,
          "_blank"
        );
      }
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
